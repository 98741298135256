<!--
  Used to mint a Token Contract (Collection).
-->
<template>
  <div class="form-container mint-step">
    <div class="header">
      <h3 class="step-title accented">Define Collection</h3>

      <div class="buttons">
        <button v-if="!existingCollection" class="text small" @click="showCollectionsModal">
          Use existing collection<AddIcon />
        </button>
        <button v-else class="text small" @click="chooseNewCollection">
          Create new collection
          <AddIcon />
        </button>
        <button class="text small skip" @click="skipCollection">
          Skip<BackIcon mirror :size="16" />
        </button>
      </div>
    </div>
    <form>
      <TextInput
        v-model="mintingCollection.name"
        label="Collection Name"
        name="collection-name"
        :disabled="existingCollection"
        required
        @input="updateCollectionName"
      />
      <TextInput
        v-model="mintingCollection.contractId"
        label="Collection ID"
        name="collection-id"
        :disabled="existingCollection"
      />
      <TextInput
        v-model="computedId"
        disabled
        label="Collection Contract ID"
        name="collection-contract-id"
      />
      <v-select
        v-model="mintingCollection.collectionGroup"
        :items="collectionGroups || []"
        item-text="name"
        item-value="id"
        return-object
        hide-details
        label="Collection Group"
      />
      <MediaPicker
        v-model="mintingData.collectionFileData"
        :src="mintingCollection.image || ''"
        :disabled="existingCollection"
        label="Collection Preview"
        required
        :requiredWidth="540"
        :requiredHeight="540"
      />
      <TextArea
        v-model="mintingCollection.description"
        label="Collection Description"
        name="collection-description"
        :disabled="existingCollection"
      />
      <TextArea
        v-model="mintingCollection.internalNotes"
        label="Internal Notes"
        name="internal-notes"
        height="50px"
        :disabled="existingCollection"
      />
      <Properties
        ref="collection-properties"
        title="Collection Properties"
        mode="collection"
        v-model="mintingCollection.properties"
        :disabled="existingCollection"
      />
      <Properties
        ref="default-token-properties"
        title="Default Token Properties"
        mode="token"
        v-model="mintingCollection.tokenProperties"
        :disabled="existingCollection"
      >
        <div class="property-single input-container">
          <div class="row">
            <h6 class="label">Total Issuance</h6>
            <div class="fields">
              <input
                v-model="mintingCollection.defaultCap"
                type="number"
                class="small"
                placeholder="0 to 10,000"
              >
            </div>
          </div>
        </div>
      </Properties>
      <WalletsData />
      <ExtendingAttributes
        ref="collection-attributes"
        v-model="mintingCollection.attributes"
        :disabled="existingCollection"
      />

      <div class="token-variants input-container">
        <h4>Token Variants</h4>
        <VariantsEditor
          v-model="mintingCollection.variants.primary"
          mode="primary"
          label="Primary"
        />
        <VariantsEditor
          v-model="mintingCollection.variants.secondary"
          label="Secondary"
        />
      </div>

    </form>

    <div class="submit-buttons">
      <button class="standard" @click="saveDraftCollection">Save as Draft</button>
      <button
        class="standard"
        @click="mintCollection"
      >
        Mint Collection
      </button>
      <ValidationErrors :errors="validationErrors" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  name: 'MintCollection',
  data () {
    return {
      existingCollection: false,
      showingCollectionModal: false,
      generatedMetadata: {},
      contractId: null,
      validationErrors: []
    }
  },
  computed: {
    ...mapGetters([
      'userProfile',
      'mintingCollection',
      'collectionGroups',
      'globalSettings',
      'tokenContracts'
    ]),
    mintingData: {
      get () {
        return this.$store.state.mintingData
      },
      set (val) {
        this.$store.setMintingCollection(val)
      }
    },
    computedId () {
      return `${this.mintingCollection.contractId}.blkops.${process.env.VUE_APP_NEAR_NETWORK === 'mainnet' ? 'near' : 'testnet'}`
    }
  },
  mounted () {
    this.tokenContractsListener()
  },

  methods: {
    ...mapActions([
      'tokenContractsListener'
    ]),

    ...mapMutations([
      'setLoading',
      'setMintingCollection',
      'setGlobalState'
    ]),

    back () {
      this.$router.back()
    },

    showCollectionsModal () {
      console.log('Showing collections.')
      this.showingCollectionModal = true
    },

    skipCollection () {
      this.$router.push('/mint-token')
    },

    updateCollectionName () {
      let formatted = this.mintingCollection.name
      formatted = formatted.toLowerCase().replaceAll(' ', '-')
      this.$set(this.mintingCollection, 'contractId', formatted)
    },

    chooseCollection (collection) {
      this.$refs['collection-properties'].clearProperties()
      this.$refs['collection-attributes'].clearAttributes()
      this.setMintingCollection({})
      this.setMintingCollection(collection)
      this.existingCollection = true
      this.closeModal()
    },

    chooseNewCollection () {
      this.existingCollection = false
    },

    closeModal () {
      this.showingCollectionModal = false
    },

    formValid () {
      this.validationErrors = []
      if (!this.mintingCollection.name) {
        this.validationErrors.push('A collection name is required.')
      }
      if (!this.mintingData.collectionFileData) {
        this.validationErrors.push('The collection requires a preview image.')
      }
      if (!this.mintingCollection.collectionGroup) {
        this.validationErrors.push('The collection should be assigned to an existing collection group.')
      }
      if (!this.validationErrors.length) {
        return true
      } else {
        return false
      }
    },

    /**
     * 1. Format simple metadata based on form inputs.
     */
    initMetadata () {
      this.generatedMetadata = {
        name: this.mintingCollection.name,
        collectionGroup: this.mintingCollection.collectionGroup
          ? { name: this.mintingCollection.collectionGroup.name, id: this.mintingCollection.collectionGroup.id }
          : '',
        description: this.mintingCollection.description || '',
        attributes: this.mintingCollection.attributes || [],
        publisher: this.globalSettings.marketName || '',
        // 2.0 contracts are those updated by Ben Kurrek
        contractVersion: '2.0'
      }
    },

    /**
     * Generate collection media utilities to be used for display in the Market
     * These are added to "off-chain" storage so that assets can be pulled quickly when necessary,
     * rather than waiting on long loads from the IPFS address stored in metadata.
     */
    async generateCollectionUtilities () {
      console.log(`Uploading utility assets to generated-assets/${this.computedId}/image`)
      await this.fbCall('cloudinary-uploadAsset', {
        path: `generated-assets/${this.computedId}/image`,
        asset: this.mintingData.collectionFileData.rawData
      })
      return 'Finished generating collection utilities.'
    },

    /**
     * Publish assets to IPFS
     */
    async publishCollectionAssets () {
      console.log('Publishing collection assets...')
      const hash = await this.clientSideIpfsUpload(this.mintingData.collectionFileData.fileData)
      this.generatedMetadata.imageHash = hash
      this.generatedMetadata.image = this.getIpfsUrl(hash)
      console.log('Published image asset:', this.generatedMetadata.image)
    },

    /**
     * Generate IPFS url from gathered / generated metadata
     */
    async publishJsonData () {
      console.log('Publishing collection metadata...', this.generatedMetadata)
      const hash = await this.addAssetToIpfs(this.createJsonBlob(this.generatedMetadata))
      this.mintingCollection.metadataHash = hash
      this.mintingCollection.metadataUrl = this.getIpfsUrl(hash)
      console.log('Published JSON data:', this.mintingCollection.metadataUrl)
    },

    async uploadVariantsMedia () {
      for (const i in this.mintingCollection.variants.primary) {
        const variant = this.mintingCollection.variants.primary[i]
        if (variant.image && variant.image.rawData) {
          await this.uploadPrimaryVariantMedia(this.computedId, variant)
          variant.hasImage = true
          delete variant.image
        }
      }
    },

    async saveDraftCollection () {
      console.log('Saving draft.')
    },

    async mintCollection () {
      if (!this.formValid()) {
        console.log('The form had errors.')
        return
      }
      this.setLoading(true)
      this.initMetadata()
      try {
        this.setGlobalState({ target: 'loadingStatus', val: 'Uploading your assets. Please don\'t close this browser window.' })
        await this.generateCollectionUtilities()
      } catch (err) {
        this.setLoading(false)
        this.addNotification('Error publishing assets.', 'error')
        throw err
      }
      try {
        this.setGlobalState({ target: 'loadingStatus', val: 'Uploading your assets to IPFS. Please don\'t close this browser window.' })
        await this.publishCollectionAssets()
      } catch (err) {
        this.setLoading(false)
        this.addNotification(err.message, 'error')
        throw err
      }
      try {
        this.setGlobalState({ target: 'loadingStatus', val: 'Uploading metadata to IPFS. Please don\'t close this browser window.' })
        await this.publishJsonData()
      } catch (err) {
        this.setLoading(false)
        this.addNotification('Error uploading JSON data to IPFS.', 'error')
        throw err
      }
      try {
        this.setGlobalState({ target: 'loadingStatus', val: 'Uploading image assets for Primary Variants. Please don\'t close this browser window.' })
        await this.uploadVariantsMedia()
      } catch (err) {
        this.setLoading(false)
        this.addNotification(err.message, 'error')
        throw err
      }
      try {
        this.setGlobalState({ target: 'loadingStatus', val: 'Minting new collection. This may take some time.' })
        await this.fbCall('minting-newCollection', {
          mintingCollection: this.mintingCollection,
          generatedMetadata: this.generatedMetadata,
          ...(this.mintingCollection.contractId) && { contractId: this.mintingCollection.contractId }
        })
      } catch (err) {
        this.setLoading(false)
        this.addNotification('Error minting new collection.', 'error')
        throw err
      }
      this.setLoading(false)
      this.setGlobalState({ target: 'loadingStatus', val: '' })
      this.addNotification('Successfully minted new collection.', 'success', false)
    }
  }
}
</script>

<style lang="scss" scoped>
h4 {
  margin: $space-xl 0 $space-ml;
}
.submit-buttons {
  button {
    margin-right: $space-m;
  }
}
</style>
